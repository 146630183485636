import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function purchaseinboundUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // {key: 'show_details', label: '详细内容', sortable: true},
    // { key: 'inbound_id', label: 'ID', sortable: true },
    { key: 'inbound_no', label: '入库单编号', sortable: true },
    // { key: 'order_id', label: '采购单ID', sortable: true },
    { key: 'order_no', label: '采购单编号', sortable: true },
    // { key: 'warehouse_id', label: '仓库ID', sortable: true },
    { key: 'warehouse_name', label: '仓库名称', sortable: true },
     { key: 'delivery_type', label: '物流方式', sortable: true },
    // { key: 'transport_type', label: '运输方式', sortable: true },
    // { key: 'delivery_time', label: '发货时间', sortable: true },
    { key: 'arrival_time', label: '预计到达时间', sortable: true },
    // { key: 'carrier', label: '承运商', sortable: true },
    // { key: 'express_no', label: '快递单号', sortable: true },
    { key: 'status', label: '入库状态', sortable: true },
    { key: 'status_sales', label: '采购确认', sortable: true },
    { key: 'inbound_type', label: '入库类型', sortable: true },
    { key: 'attach', label: '采购附件', sortable: true },
    { key: 'memo', label: '采购备注', sortable: true },
    // { key: 'info', label: '详细信息', sortable: true },
    { key: 'attachments_wh', label: '仓库附件'},
    { key: 'memo_wh', label: '仓库备注'},
     //{ key: 'modify_time', label: '提交仓库时间', sortable: true },
    // { key: 'state', label: '状态', sortable: true },
    { key: 'submit_time', label: '提交时间'},
    { key: 'submiter', label: '提交人', sortable: true },
     //{ key: 'add_time', label: '添加时间', sortable: true },
    { key: 'creator', label: '添加人', sortable: true },
    // { key: 'updator', label: '修改人', sortable: true },
    { key: 'inbound_time', label: '入库时间', sortable: true },
    { key: 'inbound_creator', label: '入库人', sortable: true },
    { key: 'business_time', label: '业务时间', sortable: true },
        { key: 'actions', label: '操作' },
  ]
  /*if (config.role.isWarehouseStaff){
    tableColumns.splice(tableColumns.length -1,1)
  }*/

  if (config.role.isExpressStaff || config.role.isSuper){
    tableColumns.splice(tableColumns.length - 1,0,{ key: 'express_check_status', label: '物流审核', sortable: true })
  }

  if (config.role.isFinanceStaff || config.role.isSuper){
    tableColumns.splice(tableColumns.length - 1,0,{ key: 'is_finance', label: '财务审核', sortable: true })
  }

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('inbound_id')
  const isSortDirDesc = ref(true)
  const searchType = config
  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['purchaseinbound/getCondition']}

    condition.warehouseName = undefined
    condition.creatorName = undefined
    condition.inboundCreatorName = undefined

    const params = {
      start: start.value,
      limit: limit.value,
      order_by: orderBy.value,
      order_desc: isSortDirDesc.value === true ? 'desc':'',
      status:'0,1,2',
      inbound_id:config.inbound_id,
      isStockLog:config.isStockLog
    }
    Object.assign(params,condition)
    const warehouseArray = []
    if (!isEmpty(condition.warehouseArray)){
      condition.warehouseArray.forEach((value, index, array) => {
        warehouseArray.push(value.value)
      })
    }
    params.warehouseIds = warehouseArray.join(",")
    store
      .dispatch('purchaseinbound/warehouseSearch', params)
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    searchType,
    refetchData,

    // Extra Filters
  }
}
