<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Search -->
          <search @refreshTable=refetchData :role=role></search>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>

          </b-col>
          <b-col
              cols="12"
              md="6"
              v-show="selectShow"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  variant="outline-primary"
                  class="mr-1"
                  @click="selectAll"
              >
                <span class="align-right">{{ selectedBtn }}</span>
              </b-button>
              <v-select
                  v-model="selectKey"
                  :options="selectArr"
                  :clearable="false"
                  :reduce="item=>item.value"
                  class="d-inline-block mx-50 mr-1"
                  style="width: 100px"

              />
              <b-button
                  variant="outline-primary"
                  class="mr-1"
                  @click="changeStatusList(1)"
              >
                <span class="align-right">审核通过</span>
              </b-button>
              <b-button
                  variant="outline-primary"
                  class="mr-1"
                  @click="changeStatusList(0)"
                  v-show="selectKey !== 'express_check_status'"
              >
                <span class="align-right">审核不通过</span>
              </b-button>

            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"

          selectable
          selected-variant="primary"
          select-mode="multi"
          @row-selected="onRowSelected"
      >

        <!-- Columns 显示隐藏详细信息 -->
        <!-- full detail on click -->
        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.items" :fields="fields">
                <template #cell(ext)="data">
                  {{ data.item.ext.code }}
                </template>
                <template #cell(purchase_return)="data">
                  {{ data.item.ext.purchase_return }}
                </template>

                <template #cell(name)="data">
                  [#{{ data.item.product_id }}]{{ data.item.name }}
                </template>

                <template #cell(purchase_team)="data">
                  {{ getCodeLabel('purchase_team', data.item.purchase_team_id) }}
                </template>

                <!-- Columns -->
                <template #cell(inventory_type)="data">
                  {{ getCodeLabel('inbound_inventory_type', data.item.inventory_type) }}
                </template>
              </b-table>
            </div>
          </b-card>
        </template>

        <template #cell(warehouse_name)="data">
          {{ data.item.warehouse_name }}
          <feather-icon
              icon="UserIcon"
              size="16"
              @click="showWarehouseCharge(data.item)"
          />
        </template>
        <!-- Columns -->
        <template #cell(inbound_no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button
          >
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          [#{{ data.item.inbound_id }}]{{ data.item.inbound_no }}
        </template>

        <template #cell(order_no)="data">
          [#{{ data.item.order_id }}]{{ data.item.order_no }}
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">

          <b-badge
              pill
              :variant="`light-${getCodeColor('purchase_inbound_status',data.item.status)}`"

          >
            {{ getCodeLabel('purchase_inbound_status', data.value) }}
          </b-badge>
        </template>

        <!-- Column: Type -->
        <template #cell(purchaseinbound_type)="data">
          {{ getCodeLabel('purchaseinbound_type', data.item.purchaseinbound_type) }}
        </template>

        <!-- Column: arrival_time -->
        <template #cell(arrival_time)="data">
          {{ toTime(data.item.arrival_time) }}
        </template>

        <!-- Column: delivery_time -->
        <template #cell(delivery_time)="data">
          {{ toTime(data.item.delivery_time) }}
        </template>

        <!-- Column: transport_type -->
        <template #cell(transport_type)="data">
          {{ getCodeLabel('transport_type', data.item.transport_type) }}
        </template>

        <!-- Column: delivery_type -->
        <template #cell(delivery_type)="data">
          {{ getCodeLabel('delivery_method_purchase', data.item.delivery_type) }}
        </template>

        <template #cell(inbound_type)="data">
          {{ getCodeLabel('inbound_type', data.item.inbound_type) }}
        </template>

        <template #cell(status_sales)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('purchase_inbound_status_sales',data.value)}`"

          >
            {{ getCodeLabel('purchase_inbound_status_sales', data.value) }}
          </b-badge>
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(submiter)="data">
          {{ getCodeLabel('user', data.item.submiter) }}
        </template>

        <template #cell(inbound_time)="data">
          {{ toTime(data.item.inbound_time) }}
        </template>

        <template #cell(submit_time)="data">
          {{ toTime(data.item.submit_time) }}
        </template>

        <template #cell(business_time)="data">
          {{ toTime(data.value) }}
          <feather-icon icon="EditIcon" @click="showBusinessTime(data.item)"
                        v-if="isFinanceStaff || isSuper || data.item.is_finance === 0"/>
        </template>

        <template #cell(inbound_creator)="data">
          {{ getCodeLabel('user', data.item.inbound_creator) }}
        </template>

        <!-- Column: State -->
        <template #cell(express_check_status)="data">
          <b-link
              @click="changeStatus(data.item,'express_check_status')"
          >
            <b-badge
                pill
                :variant="`light-${getCodeColor('yesno',data.value)}`"

            >
              {{ getCodeLabel('yesno', data.value) }}
            </b-badge>
          </b-link>
        </template>

        <template #cell(is_finance)="data">
          <b-link
              @click="changeStatus(data.item,'is_finance')"
          >
            <b-badge
                pill
                :variant="`light-${getCodeColor('yesno',data.value)}`"

            >
              {{ getCodeLabel('yesno', data.value) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: remark -->
        <template #cell(memo)="data">

          <div :id="`memo-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
              :target="`memo-row-${data.item.id}`"
              placement="top"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <template #cell(attach)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attach'"
                             :id="data.item.attach"
                             :object_type="'allot_outbound'"
                             :object_id="0"
                             :readonly="true"
                             @change="onUploaded"
          />
        </template>

        <template #cell(attachments_wh)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments_wh'"
                             :id="data.item.attachments_wh"
                             :object_type="'allot_outbound'"
                             :object_id="0"
                             :readonly="true"
                             @change="onUploaded"
          />
        </template>

        <template #cell(memo_wh)="data">
          <!-- 备注 -->
          <div :id="`memo_wh-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
              :target="`memo_wh-row-${data.item.id}`"
              placement="top"
          >
            {{ data.value }}
          </b-tooltip>
        </template>


        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
              right
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="toLODOP(data.item.inbound_id)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">打印</span>

            </b-dropdown-item>

            <b-dropdown-item
                @click="onInbound(data)"
                v-if="data.item.status!=2"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">我要入库</span>
            </b-dropdown-item>

            <b-dropdown-item @click="commit(data.item)" v-if="data.item.status!=2">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">入库完成</span>

            </b-dropdown-item>

            <b-dropdown-item @click="showModal(data.item.inbound_id)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">入库明细</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status === 1" @click="resetPurchaseInbound(data.item.id)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">驳回</span>
            </b-dropdown-item>

            <div v-if="data.item.status === 2">
              <b-dropdown-item @click="rollback(data.item)">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">申请回滚</span>
              </b-dropdown-item>
              <b-dropdown-item
                  :to="{ name: 'apps-stockrollback-list', query: { order_type:1,relational_id: data.item.id } }"
              >
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">查看库存回滚审批</span>
              </b-dropdown-item>
            </div>

            <b-dropdown-item v-if="data.item.status === 2" @click="attachWh(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">入库附件</span>
            </b-dropdown-item>

            <!--装卸、物流费-->
            <b-dropdown-item @click="showChargeModal(data.item,1)" v-if="data.item.status === 2">
              <feather-icon icon="ToolIcon"/>
              <span class="align-middle ml-50">生成装卸费</span>
            </b-dropdown-item>
            <b-dropdown-item @click="showChargeModal(data.item,2)" v-if="data.item.status === 2">
              <feather-icon icon="ToolIcon"/>
              <span class="align-middle ml-50">生成物流费</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <b-modal
          id="modal-inbound-item"
          ok-only
          ok-title="确认"
          cancel-title="取消"
          centered
          size="xl"
          title="查看入库明细"
          ref="modal"
      >
        <stock-inbound-item-list ref="inboundItem" :inbound_id="inbound_id">

        </stock-inbound-item-list>
      </b-modal>
    </b-card>

    <!--入库仓库人查看-->
    <b-modal
        id="modal-warehouse-charge"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="lg"
        title="仓库负责人"
        ref="modalWarehouseCharge"
    >
      <warehouse-charge-list :warehouseId="itemWarehouseId" :warehouseName="itemWarehouseName">

      </warehouse-charge-list>

    </b-modal>
    <!-- 添加附件窗口 -->
    <b-modal
        id="fileModal"
        size="lg"
        title="添加附件"
        ref="fileModal"
        hide-footer
    >
      <ValidationObserver ref="observer">
        <div>

          <b-col md="12">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="备注"
                label-size="sm"
            >
              <b-form-textarea v-model="contentInfo"></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="附件"
                label-for="attachments_ids"
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload theme="files"
                                 attachment_id="attachments_wh"
                                 :id="attachmentsWh"
                                 object_type="purchase_inbound"
                                 :object_id="inbound_id"
                                 @change="onUploaded"
              />
            </b-form-group>
          </b-col>
          <!--          toTime(business_time)-->
          <b-col md="12">
            <xy-date-picker label-cols="2" label="业务时间" :val.sync="business_time"
                            :config="{enableTime: true,maxDate: toTime(new Date().getTime()/1000)}" rules="required"
                            id="business_time"
            >
            </xy-date-picker>
          </b-col>

          <b-col md="12">
            <b-row>
              <b-col md="6">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="车牌号"
                    label-for="car_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="car_no"
                      size="sm"
                      v-model="car_no"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="车型"
                    label-for="car_detail"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <v-select
                      id="car_detail"
                      :options="getCodeOptions('car_detail').slice(0,11)"
                      class="select-size-sm"
                      v-model="car_detail"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col md="12">
            <b-row>
              <b-col md="6"
              >
                <modal-select
                    label="装卸队*"
                    type="input"
                    v-on:change="fromChildren($event,['company_name','company_id'])"
                    :params="['company_name_load','company_id_load']"
                    :value="company_name_load"
                    modalName="装卸队"
                    :warehouseId="warehouseIdModel"
                    placeholder="点击选择装卸队"
                >
                </modal-select>
              </b-col>
              <b-col md="6"
              >
                <modal-select
                    :label='label_name'
                    type="input"
                    v-on:change="fromChildrenTransport($event,['company_name','company_id'])"
                    :params="['company_name_transport','company_id_transport']"
                    :value="company_name_transport"
                    modalName="承运商"
                    :show-all-logistics="false"
                    :warehouse-id-to="warehouseIdModel"
                    placeholder="点击选择承运商"
                >
                </modal-select>
              </b-col>
            </b-row>
          </b-col>

          <b-col
              cols="12"
              class="mt-50"
          >
            <b-button
                variant="primary"
                class="mr-1"
                @click="submitInfo"
            >
              保存
            </b-button>
          </b-col>
        </div>
      </ValidationObserver>
    </b-modal>

    <b-modal
        id="modal-rollback"
        centered
        size="lg"
        hide-footer
        title="库存回滚申请"
        ref="rollbackModal"
    >
      <stock-rollback-edit :relation="curRow" :orderType="1" @closeModal="closeModalRollback"
      ></stock-rollback-edit>
    </b-modal>

    <b-modal
        id="businessTimeModal"
        size="lg"
        centered
        title="修改业务时间"
        ref="businessTimeModal"
        hide-footer
    >
      <business-time :id="inbound_id" @hide="hideBusinessTime"></business-time>
    </b-modal>

    <b-modal
        id="pdfModal"
        centered
        title="PDF"
        ref="pdfModal"
        hide-footer
    >
      <attachment-upload theme="preview"
                         attachment_id="attachments"
                         :id="pdf_id"
                         :readonly="true"
                         v-if="pdf_id"
      />
      <div v-else>
        <p class="my-4">正在生成，请稍候!</p>
      </div>

    </b-modal>

    <b-modal
        id="attachWhModal"
        centered
        title="入库附件"
        ref="attachWhModal"
        @ok="saveAttachWh"
        cancel-title="取消"

    >
      <attachment-upload :theme="'files'"
                         :attachment_id="'attachments_wh'"
                         :id="PIAttachmentsWhId"
                         :object_type="'purchase_inbound'"
                         :object_id="PIId"
                         @change="attachWhLoaded"
      />
    </b-modal>

    <fee-charge-select ref="feeRef" :object-type=1 :object-id=inbound_id :warehouse-id=warehouseIdModel :warehouse-id-to=warehouseIdModel></fee-charge-select>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, reactive, toRefs, watch, onMounted, onBeforeMount} from '@vue/composition-api'
import {
  avatarText,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  get_upload_url,
  lodopWatermark,
  isEmpty,
} from '@core/utils/filter'
import {toTime, toDate, toMonth} from '@core/utils/dayjs/utils'
import purchaseinboundUseList from './warehouseUseList'
import purchaseinboundStore from './purchaseinboundStore'
import {useToast} from 'vue-toastification/composition'
import StockInboundItemList from '@/views/apps/stockinbounditem/inboundItem-modal/StockInboundItemList'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import WarehouseList from '@/views/apps/purchaseinbounditem/WarehouseList'
import {useRouter} from '@core/utils/utils'
import Search from '@/views/apps/purchaseinbound/warehouseSearch'
import StockRollbackEdit from '@/views/apps/stockrollback/StockRollbackEdit'
import XyDatePicker from '@/views/components/xy/XyDatePicker'
import {getUserData} from '@/auth/utils'
import BusinessTime from '@/views/apps/purchaseinbound/components/businessTime'
import WarehouseChargeList from "@/views/apps/warehousecharge/warehousecharge-modal/WarehouseChargeList";
import stockrollbackStore from "@/views/apps/stockrollback/stockrollbackStore";
import lodopStore from "@/views/apps/lodop/lodopStore";
import {getLodop} from "@/libs/LodopFuncs";
import XyInput from "@/views/components/xy/XyInput";
import ModalSelect from "@/views/components/modal/ModalSelect";
import axios from '@axios';
import FeeChargeSelect from "@/views/apps/feeloadsetting/FeeChargeSelect";

export default {
  components: {
    XyInput,
    WarehouseChargeList,
    BusinessTime,

    XyDatePicker,
    StockRollbackEdit,
    Search,
    StockInboundItemList,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
    WarehouseList,
    ModalSelect,
    FeeChargeSelect,
  },
  data() {
    return {
      inbound_id: 0,
      memoFile: '',
      contentInfo: '',
      attachmentsWh: '',
      business_time: '',
      company_name_load: '',
      company_id_load: '',
      company_name_transport: '',
      company_id_transport: '',
      warehouseIdModel: 0,
      car_no: '',
      car_detail: '',
      label_name: '',
      delivery_type:'',

      inboundId: 0,
      fields: [
        {
          key: 'ext',
          label: '商品69码',
          sortable: true
        },
        {
          key: 'name',
          label: '商品名称',
          sortable: true
        },
        {
          key: 'purchase_team',
          label: '销售权',
          sortable: true
        },
        {
          key: 'is_gift',
          label: '是否赠品',
          sortable: true
        },
        {
          key: 'qty',
          label: '计划入库',
          sortable: true
        },
        {
          key: 'trueqty',
          label: '已入库',
          sortable: true
        },
        {
          key: 'purchase_return',
          label: '采购退料'
        },
        {
          key: 'inventory_type',
          label: '盘点类型',
          sortable: true
        },
      ],
      PIId: 0,
      PIAttachmentsWhId: '',
      attachmentsWhId: '',
    }
  },
  methods: {
    showModal(id) {
      this.inbound_id = id
      this.$refs['modal'].show()
    },
    commit(params) {
      this.inbound_id = params.inbound_id
      if (params.arrival_time > new Date().getTime() / 1000) {
        this.business_time = null
      } else {
        this.business_time = params.arrival_time
      }
      this.warehouseIdModel = params.warehouse_id
      if (params.delivery_type == 2) {
        //物流方式为“行云自提”时，承运商必填
        this.label_name = '承运商'
        this.delivery_type = '2'
        //查询承运商
        axios.post('/api/company/searchLogistics', {
          'showAllLogistics': false, 'warehouseIdFrom': 0, 'warehouseIdTo': this.warehouseIdModel,
        }).then(res => {
          if (res.data.code === 0) {
            const list = res.data.data.list
            if (list.length > 0) {
              this.company_name_transport = list[list.length - 1].company_name
              this.company_id_transport = list[list.length - 1].company_id
            } else {
              this.company_name_transport = ''
              this.company_id_transport = ''
            }
          }
        })
      } else {
        this.label_name = '承运商'
        this.delivery_type = ''
        this.company_name_transport = ''
        this.company_id_transport = ''
      }
      //查询装卸队
      axios.post('/api/company/searchLoadTeam', {
        'showAllLogistics': false, 'warehouseId': this.warehouseIdModel,
      }).then(res => {
        if (res.data.code === 0) {
          const list = res.data.data.list
          if (list.length > 0) {
            this.company_name_load = list[list.length - 1].company_name
            this.company_id_load = list[list.length - 1].company_id
          } else {
            this.company_name_load = ''
            this.company_id_load = ''
          }
        }
      })
      this.car_no = ''
      this.car_detail = ''
      this.$refs['fileModal'].show()
    },
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('purchaseinbound')) store.registerModule('purchaseinbound', purchaseinboundStore)
    if (!store.hasModule('stockrollback')) store.registerModule('stockrollback', stockrollbackStore)
    if (!store.hasModule('lodop')) store.registerModule('lodop', lodopStore)

    const router = useRouter()


    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseinbound')) store.unregisterModule('purchaseinbound')
      if (store.hasModule('stockrollback')) store.unregisterModule('stockrollback')
      if (store.hasModule('lodop')) store.unregisterModule('lodop')
    })

    const onUploaded = function (id, attachment, result) {
      this.attachmentsWh = result
    }

    const attachWhLoaded = function (id, attachment, result) {
      this.attachmentsWhId = result
    }

    const submitInfo = async function () {
      const validate = await state.observer.validate()
      if (!validate) {
        toast.error('请正确填写必填字段！')
        return
      }
      if (this.business_time == null) {
        toast.error('请填写时间！')
        return
      }
      if (!isEmpty(this.company_name_transport) && isEmpty(this.car_detail)) {
        toast.error('请选择车型！')
        return
      }
      // if(isEmpty(this.company_name_transport) && this.delivery_type == '2'){
      //   toast.error('请选择承运商！')
      //   return
      // }
      // if(isEmpty(this.company_name_load)){
      //   toast.error('请选择装卸队！')
      //   return
      // }

      this.$refs['fileModal'].hide()
      store.dispatch('purchaseinbound/finishinbound', {
        id: this.inbound_id,
        'attachmentsWh': this.attachmentsWh,
        'memoWh': this.contentInfo,
        'business_time': this.business_time,
        'company_id_load': this.company_id_load,
        'company_id_transport': this.company_id_transport,
        'company_name_transport': this.company_name_transport,
        'company_name_load': this.company_name_load,
        'car_no': this.car_no,
        'car_detail': this.car_detail,
      })
          .then(res => {
            if (res.data.code === 0) {
              toast.success('提交成功')
              refetchData()
            }else{
              toast.error(res.data.data)
            }
          })
    }

    const resetPurchaseInbound = function (purchaseInboundId) {
      store.dispatch('purchaseinbound/resetPurchaseInboundData', {inboundId: purchaseInboundId})
          .then(res => {
            if (res.data.code === 0) {
              toast.success(res.data.data)
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
    }

    const attachWh = function (params) {
      this.PIId = params.id
      this.PIAttachmentsWhId = params.attachments_wh
      this.attachmentsWhId = ''
      this.$refs['attachWhModal'].show()
    }

    const saveAttachWh = function () {
      store.dispatch('purchaseinbound/saveAttachmentsWh', {id: this.PIId, attachmentsWh: this.attachmentsWhId})
          .then(res => {
            if (res.data.code === 0) {
              toast.success(res.data.data)
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
    }

    const toLODOP = function (params) {
      store.dispatch('lodop/createPurchaseInboundLODOP', {
        id: params,
      }).then(res => {
        if (res.data.code === 0) {
          const data = res.data.data
          var LODOP = getLodop();
          if (LODOP == undefined) {
            toast.error("打印插件未安装，请安装插件!")
            return;
          }
          LODOP.PRINT_INIT('打印入库单') //打印初始化
          //LODOP.SET_PRINTER_INDEX(printer) //选择的打印机类型（注意这里是打印机下标，0开始的）
          LODOP.SET_PRINT_STYLE('FontSize', 18) //设置对象风格
          // this.LODOP.ADD_PRINT_TEXT(50, 521, 130, 39, this.description) //增加纯文本项
          LODOP.SET_PRINT_PAGESIZE(1, 2200, 1400, '') //设定纸张大小
          // LODOP.SET_PRINT_MODE('PRINT_PAGE_PERCENT', '55%')//设置缩放
          LODOP.SET_PREVIEW_WINDOW(2, 2, 0, 0, 0, '')//设置窗口
          var html = ''
          var item = ``
          var items = data.ext.item
          for (let j = 0; j < items.length; j++) {
            item += ` <tr height="23px">
                        <td align="center">${items[j].ext.no}</td>
                        <td align="center">${items[j].ext.code}</td>
                        <td align="left">${(items[j].name.length > 28) ? items[j].name.substring(0, 28) : items[j].name}</td>
                        <td align="center">${items[j].ext.specification}</td>
                        <td align="center">${items[j].ext.productUnit}</td>
                        <td align="center">${items[j].qty == undefined ? 0 : items[j].qty}</td>
                        <td align="center">${items[j].trueqty == undefined ? 0 : items[j].trueqty}</td>
                        <td align="center"></td>
                    </tr>`
          }
          html = `
                <div style="width:780px">
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="30px">
                        <td colspan="2">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr height="30px">
                                    <td width="50%">${data.ext.time}(第${data.print_times}次)</td>
                                    <td width="20%" align="center"><font face="楷体" size="4">入库单</font></td>
                                    <td width="30%" align="right"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr height="20px">
                        <td width="33%">收货仓库：${data.warehouse_name}</td>
                        <td width="33%">类型：采购入库</td>
                        <td width="33%">单价编号：${data.inbound_no}</td>
                    </tr>
                    <tr height="20px">
                        <td width="33%">发货单位：${data.ext.supplierName}</td>
                        <td width="33%">计划日期：${data.arrival_time == undefined ? '' : toDate(data.arrival_time)}</td>
                        <td width="33%">入库时间：${data.inbound_time == undefined ? '' : toTime(data.inbound_time)}</td>
                    </tr>
                </table>
                <table width="100%" class="protable">
                    <tr height="25px">
                        <td width="4%" align="center">序号</td>
                        <td width="12%" align="center">69码</td>
                        <td align="center">商品名称</td>
                        <td width="8%" align="center">规格</td>
                        <td width="5%" align="center">单位</td>
                        <td width="6%" align="center">计划数量</td>
                        <td width="7%" align="center">本次数量</td>
                        <td width="18%" align="center">备注</td>
                    </tr>
                    ${item}
                    <tr height="23px">
                        <td align="center"></td>
                        <td align="center">合计</td>
                        <td align="center"></td>
                        <td align="center"></td>
                        <td align="center"></td>
                        <td align="center">${data.ext.allQty}</td>
                        <td align="center">${data.ext.allTrueQty}</td>
                        <td align="center"></td>
                    </tr>
                </table>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr height="20px">
                        <td width="100%">通知备注：${data.memo == undefined ? '' : data.memo}</td>
                </tr>
                </table>

                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="20px">
                        <td colspan="3">
                          <table width="100%" border="0" cellspacing="0" cellpadding="0">
                              <tr height="25px">
                                <td width="34%">制单人：${data.ext.user}</td>
                                <td width="33%">仓库备注：${data.memo_wh == undefined ? '' : data.memo_wh}</td>
                                <td width="33%"></td>
                              </tr>
                          </table>
                        </td>
                    </tr>
                    <tr height="20px">
                        <td width="25%" >车型：${getCodeLabel("car_detail",data.car_detail)}</td>
                        <td width="25%">车牌号码：${isEmpty(data.car_no)?'':data.car_no}</td>
                        <td width="25%">联系电话：</td>
                        <td width="25%">司机签字：</td>
                    </tr>
                    <tr height="50px">
                        <td>收货人签字：</td>
                        <td>审核人签字：</td>
                    </tr>
                </table>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="25px">
                        <td><font size="2px">**白联：存根|红联：发货方|蓝联：财务|绿联：司机|黄联：仓管</font> **<font size="2px" style="font-weight: bold">友情提醒：货物请当面点清，事后恕不负责**</font></td>
                    </tr>
                </table>
                </div>
          `
          var style =
              "<style>" +
              "table{}" +
              "table td{font-size: 14px;white-space: nowrap;}" +
              ".protable{border-collapse: collapse;border: none;}" +
              ".protable td{border: solid #000 1px;}" +
              "</style>";
          lodopWatermark(LODOP)
          LODOP.ADD_PRINT_HTM(0, 0, 1500, 1500, style + '<body size="10px">' + html + '</body>') // 增加超文本项（打印内容即为body内容）
          LODOP.NewPage();
          LODOP.PREVIEW() // 设置预览（如果使用预览，每次打印前会弹出预览窗口，根据需要决定是否使用）
          this.refetchData()
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const {role_id} = getUserData()

    const fromChildren = function (params, modal) {
      this.company_name_load = params == null ? null : params['company_name_load']
      this.company_id_load = params == null ? null : params['company_id_load']
      this.$forceUpdate()
    }

    const fromChildrenTransport = function (params, modal) {
      this.company_name_transport = params == null ? null : params['company_name_transport']
      this.company_id_transport = params == null ? null : params['company_id_transport']
      this.$forceUpdate()
    }

    const role = {
      isWarehouseStaff: role_id === 9 || role_id === 10 || role_id === 21,
      isExpressStaff: role_id === 17 || role_id === 18,
      isFinanceStaff: role_id === 11 || role_id === 12,
      isSuper: role_id === 14
    }

    const state = reactive({
      curRow: undefined,
      rollbackModal: undefined,

      observer: undefined,
      businessTimeModal: undefined,

      pdfModal: undefined,
      pdf_id: undefined,

      selected: [],
      selectedBtn: '全选',
      selectKey: 'is_finance',
      selectArr: [{
        label: '财务',
        value: 'is_finance',
      }],
      // selectShow:role.isFinanceStaff || role.isExpressStaff || role.isSuper
      selectShow: role.isFinanceStaff || role.isSuper,

      modalWarehouseCharge: undefined,
      itemWarehouseId: 0,
      itemWarehouseName: '',
    })

    watch(() => state.selected, (newVal) => {
      state.selectedBtn = newVal.length === limit.value ? '全不选' : '全选'
    })

    onMounted(() => {
      if (role.isFinanceStaff) {
        state.selectKey = 'is_finance'
        state.selectArr.splice(1, 1)
      }

      /*if (role.isExpressStaff) {
        state.selectKey = 'express_check_status'
        state.selectArr.splice(0,1)
      }*/
    })

    const methods = {
      onInbound(data) {
        const condition = store.getters['purchaseinbound/getCondition']
        condition.inboundNo = data.item.inbound_no
        router.router.push({
          name: 'apps-purchaseinbounditem-warehouseList',
          query: {id: data.item.id, warehouseId: data.item.warehouse_id}
        })
      },
      showWarehouseCharge(params) {
        state.itemWarehouseId = params.warehouse_id
        state.itemWarehouseName = params.warehouse_name
        state.modalWarehouseCharge.show()
      },
      showBusinessTime(item) {
        this.inbound_id = item.id
        state.businessTimeModal.show()
      },
      hideBusinessTime() {
        state.businessTimeModal.hide()
        refetchData()
      },

      selectAll() {
        state.selectedBtn === '全选' ? refListTable.value.selectAllRows() : refListTable.value.clearSelected()
      },
      onRowSelected(items) {
        state.selected = items
      },
      rollback(row) {
        store.dispatch('stockrollback/isCanRollback', {id: row.inbound_id, type: 'purchase_inbound'})
            .then(res => {
              if (res.data.code === 0) {
                state.curRow = row
                state.rollbackModal.show()
              } else {
                toast.error(res.data.data)
              }
            })
        /*state.curRow = row
        state.rollbackModal.show()*/
      },
      closeModalRollback() {
        state.rollbackModal.hide()
      },
      changeStatusList(status) {
        if (!state.selectKey) {
          toast.error('请选择审批类型')
          return
        }
        const selected = state.selected.filter(item => item[state.selectKey] !== status)
            .map(item => {
              const model = {
                inbound_id: item.id
              }
              model[state.selectKey] = status
              return model
            })
        if (!selected.length) {
          toast.error(`未选择记录或已全部审批，请勿重复点击`)
          return
        }
        store.dispatch('purchaseinbound/updateModelList', {selected: selected})
            .then(res => {
              if (res.data.code === 0) {
                toast.success(res.data.data)
                refetchData()
              } else {
                toast.error(res.data.data)
              }
            })
      },
      changeStatus(data, key) {
        if (key === 'express_check_status' && data[key] === 1) {
          toast.error('已经审批，请勿重复点击')
          return false
        }
        const params = {inbound_id: data.id,}
        params[key] = 1 - data[key]
        store.dispatch('purchaseinbound/updateModel', params)
            .then(res => {
              if (res.data.code === 0) {
                toast.success(res.data.data)
                refetchData()
              } else {
                toast.error(res.data.data)
              }
            })
      },
    }

    if (router.route.value.query.supplier_id) {
      const condition = store.getters['purchaseinbound/getCondition']
      condition.supplier_id = router.route.value.query.supplier_id
      condition.supplierName = getCodeLabel("company", condition.supplier_id)
    }

    const showChargeModal = function (param,type){
      this.inbound_id = param.id
      this.warehouseIdModel = param.warehouse_id
      if(type === 1){
        this.$refs['feeRef'].showLoadChargeModal(param.warehouse_id,1)
      }
      if(type === 2){
        this.$refs['feeRef'].showTransportChargeModal(0,param.warehouse_id,1)
      }
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = purchaseinboundUseList({
      inbound_id: router.route.value.query.inbound_id,
      role: role,
      isStockLog: router.route.value.query.isStockLog
      // supplier_id : router.route.value.query.supplier_id
    })

    return {
      ...toRefs(state),
      ...methods,
      ...role,
      role,

      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toDate,
      isEmpty,

      // page
      toTime,
      onUploaded,
      submitInfo,
      resetPurchaseInbound,
      attachWh,
      saveAttachWh,
      attachWhLoaded,
      toLODOP,
      isWarehouseStaff: role.isWarehouseStaff,
      fromChildren,
      fromChildrenTransport,
      showChargeModal
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
